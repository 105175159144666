import { CircularProgress } from "@mui/material";
import { onSnapshot, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { dataRef } from "../lib/firebaseConfig";
import livEat_logo from "../img/livEat_logo.svg";
import launcherIcon from "../img/launcherIcon.svg";
import launcherIconDark from "../img/launcherIcon_dark.svg";
import { useNavigate } from "react-router-dom";
import DateTime from "../components/DateTime";
import iconPre from "../img/Icon-OrderPreparing.svg";
import iconReady from "../img/readyIcon.svg";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ReactPlayer from "react-player";

export const MainPage = () => {
  const navigate = useNavigate();
  const [state, setState] = useImmer({
    isBusy: true,
    data: [],
    currentIndex: 0,
  });
  useEffect(() => {
    const pin = localStorage.getItem("screen-code");
    const q = query(dataRef, where("pin", "==", pin));
    const unSubscribe = onSnapshot(
      q,
      (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        if (data.length > 0) {
          setState((draft) => {
            draft.data = data;
            draft.isBusy = false;
          });
        } else {
          localStorage.clear();
          navigate("/");
        }
        if (data[0].disconnect_device || data[0].isActive === false) {
          localStorage.clear();
          navigate("/");
        }
      },
      (error) => {
        console.log("errr", error);
      }
    );

    return () => {
      unSubscribe();
    };
  }, []);

  const handleChangeMedia = () => {
    const mediaLength = state.data?.[0]?.media.length;
    if (state.currentIndex < mediaLength - 1) {
      setState((draft) => {
        draft.currentIndex = draft.currentIndex + 1;
      });
    } else {
      setState((draft) => {
        draft.currentIndex = 0;
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (state.data?.[0]?.media?.length > 0) {
        handleChangeMedia();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [state.currentIndex, state.data?.[0]?.media]);

  if (state.isBusy) {
    return (
      <div className="loader">
        <CircularProgress />
      </div>
    );
  }

  const getMediaContent = () => {
    const currentMediaItem = state.data[0]?.media[state.currentIndex];
    return (
      <CSSTransition classNames="fade" timeout={{ enter: 1000, exit: 1000 }}>
        {currentMediaItem.type === "image" ? (
          <img alt="Content" src={currentMediaItem.src} />
        ) : currentMediaItem.type === "video" ? (
          <ReactPlayer
            url={currentMediaItem.src}
            height="100%"
            width="100%"
            playing={true}
            muted={true}
            onError={(err) => console.log("playback error", err)}
          ></ReactPlayer>
        ) : null}
      </CSSTransition>
    );
  };
  const hasAdSection = state.data?.[0]?.media?.length > 0 ? true : false;
  return (
    <>
      <div className={`screenWrapper ${state.data[0]?.dark_mode && "dark"}`}>
        <div>
          <div className="screenHead">
            <div className="logo-wrap">
              <img className="logo" src={livEat_logo} />
              <div className="store-name">{state.data[0]?.store_name}</div>
            </div>
            <div className="screenName"> {state.data[0]?.name}</div>
          </div>
          <div className="orderWrap">
            <div className={`orderStatus ${!hasAdSection && "withOutAd"} `}>
              <div className="onProgress">
                <div className="preparHead">
                  <img height="44px" src={iconPre} />
                  Preparing Order....
                </div>
                <div className="counts">
                  {state.data[0]?.orders?.today?.preparing?.map(
                    (item, index) => (
                      <span key={index}>{`${state.data[0]?.prefix ?? ""}${
                        item.id
                      }`}</span>
                    )
                  )}
                </div>
              </div>
              <div className="readyCollect">
                <div className="readyHead">
                  <img width="44px" src={iconReady} />
                  Ready to collect
                </div>
                <div className="counts">
                  {state.data[0]?.orders?.today?.ready?.map((item, index) => (
                    <span key={index}>{`${state.data[0]?.prefix ?? ""}${
                      item.id
                    }`}</span>
                  ))}
                </div>
              </div>
            </div>
            {state.data?.[0]?.media?.length > 0 && (
              <div className="orderAd">
                <TransitionGroup>{getMediaContent()}</TransitionGroup>
              </div>
            )}
          </div>
        </div>
        <footer>
          <div className="footerLogo">
            <img src={launcherIcon} className="light" />
            <img src={launcherIconDark} className="dark" />
            BFIT Orders
          </div>
          <DateTime />
        </footer>
      </div>
    </>
  );
};
